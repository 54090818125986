import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../_services/data-api.service';
import { AlertService } from '../../../_services/alert.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-multiuploadfile',
  templateUrl: './multiuploadfile.component.html',
  styleUrls: ['./multiuploadfile.component.css']
})
export class MultiuploadfileComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({url: `${environment.apiUploadFileUrl}`, itemAlias: 'file'});

  constructor(
    private alertService: AlertService,
    private _location: Location,
    private dataApiService: DataApiService
  ) { }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      //console.log('ImageUpload:uploaded:', item );
      ///console.log('ImageUpload:uploaded:', status);
      console.log('ImageUpload:uploaded:  ', response);

      let obj = JSON.parse(response);

      console.log('ImageUpload:uploaded message:  ', obj.message);
      this.alertService.success('File uploaded successfully', true);

      this.dataApiService.addAFileById(obj.message);

      console.log('to relocate');
      this._location.back();



    };
  }
}
