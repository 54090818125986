import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/authentication-service.service';
import { AlertService } from '../../_services/alert.service';
import { UserInterface } from '../../_models/UserInterface';
import { UsersService } from '../../_services/users.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private usersService: UsersService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.usersService.redirectUserOnLogin(this.router);

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('Return Url' + this.returnUrl);

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;

    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe()
      .subscribe(
        data => {
          location.reload();
        },
        error => {
          
          this.loading = false;
          if (error.error != undefined && error.error != null) {
            if (error.error.errorCode == 6) {
              this.alertService.error('User not confirmed yet. Please check your email.');
              return;
            }
          }
          this.alertService.error('Username or password incorrect');
          console.log(error);
        });
  }
}

