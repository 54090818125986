import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInterface } from '../../_models/UserInterface';
import { Router } from '@angular/router';
import { UsersService } from '../../_services/users.service';
import { AuthenticationService } from '../../_services/authentication-service.service';
import { AlertService } from '../../_services/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-user-recover-pass',
  templateUrl: './user-recover-pass.component.html',
  styleUrls: ['./user-recover-pass.component.css']
})
export class UserRecoverPassComponent implements OnInit {


  userForm: FormGroup;
  loading = false;
  submitted = false;
  user: UserInterface;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }
  ngOnInit() {

    this.userForm = this.formBuilder.group({

      email: [this.user.mail, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.userForm.value);
    this.userService.recoverPassword(this.userForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Update Data successful', true);
          this.authenticationService.logoutUser();
          this.userService.redirectUserOnLogin(this.router);
          location.reload();

        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });
  }

}
