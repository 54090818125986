import { Component, OnInit, ModuleWithComponentFactories, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../_services/users.service';

@Component({
  selector: 'app-licensing',
  templateUrl: './licensing.component.html',
  styleUrls: ['./licensing.component.css']
})
export class LicensingComponent implements OnInit {


  contactForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = false;
  messageSent = false;
  buttonText = 'Send';
  constructor(private formBuilder: FormBuilder, private userService: UsersService) { }

  ngOnInit() {

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      contactnumber: ['', Validators.required],
      username: ['', Validators.required],
      license: ['1', Validators.required]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.errorMessage = false;
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(this.f.contactnumber.value);
    console.log(this.f.license.value);

    const subject = 'License purchase';
    let message;
    switch (this.f.license.value) {
      default:
      case 1:
        message = 'Stand alone';
        break;
      case 2:
        message = 'Teacher and up to 10 students users';
        break;
      case 3:
        message = 'Teacher and more than 10 students users';
        break;
      case 4:
        message = 'Company license';
        break;
    }
    
    this.userService.sendMessage(this.f.name.value,
      this.f.contactnumber.value,
      this.f.username.value,
      subject,
      message)
      .subscribe(value => {
        this.messageSent = true;
        this.loading = false;
        this.buttonText = 'Message sent';
      },
        error => {
          this.errorMessage = true; 
          this.loading = false;
        });
  }
}
