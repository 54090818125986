import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../../_services/alert.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { UsersService } from '../../../../_services/users.service';
import { Router } from '@angular/router';
import { UserInterface } from '../../../../_models/UserInterface';
import {AuthenticationService} from "../../../../_services/authentication-service.service";
import { CourseInterface } from '../../../../_models/CourseInterface';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.css']
})
export class CreateClassComponent implements OnInit {
  createClassForm: FormGroup;
  loading = false;
  submitted = false;
  user: UserInterface;
  private courses: CourseInterface;
  fileToUpload = localStorage.getItem('fileToUpload') === "true";
  uploader: FileUploader = new FileUploader({
    url: `${environment.apiUploadFileUrl}`, headers: [{ name: 'Token', value: `${this.authenticationService.getToken()}` }],
    itemAlias: 'file', autoUpload: true
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();

    this.dataApiService
      .getMyProfessorCourses()
      .subscribe((data: CourseInterface) => (this.courses = data));

    this.createClassForm = this.formBuilder.group({
      classId: null,
      className: ['', Validators.required],
      classDescription: ['', Validators.required],
      courseId: ['', Validators.required],/// Validators.min(1)],
      fileElementList:  null


    });

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {

      console.log('ImageUpload:uploaded:', item);
      console.log('ImageUpload:uploaded:', status);
      console.log('ImageUpload:uploaded:  ', response);

      let obj = JSON.parse(response);

      console.log('ImageUpload:uploaded message:  ', obj.fileElementId);
      this.alertService.success('File uploaded successfully', true);
      this.dataApiService.addAFileById(obj.fileElementId);
    };

  }

  // convenience getter for easy access to form fields
  get f() { return this.createClassForm.controls; }

  onSubmit() {
    this.submitted = true;
    console.log("Archivos: " + this.dataApiService.files);
    // stop here if form is invalid
    if (this.createClassForm.invalid &&  !(this.createClassForm.value.courseId > 0 )) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.createClassForm.value);


    this.dataApiService.saveclass(this.createClassForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Registration successful', true);
          // this.router.navigate(['/login']);
         // a DESCOMENTAR!!!!
            this.userService.redirectUserHome(this.router);
        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });
  }

  uploadFromForm() {
    this.router.navigate(['/uploadFile']);
  }
}
