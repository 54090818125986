import { Observable, AsyncSubject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
export class NavigationService {

    private navigationObservable: BehaviorSubject<String>;
    
    getNavigationObservable(): Observable<String> {
        this.navigationObservable = new BehaviorSubject('');
        return this.navigationObservable;
    }

    sendNavigationEvent(screenName: String) {
        this.navigationObservable.next(screenName);
    }
}