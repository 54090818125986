import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './_components/home/home.component';
import { LoginComponent } from './_components/login/login.component';
import { RegisterComponent } from './_components/register/register.component';

 // Verificar accesos a rutas!!
import { AuthGuard } from './_guards/auth.guard';
import { OffersComponent } from './_components/offers/offers.component';
import { StudentHomeComponent } from './_components/student/student-home/student-home.component';
import { ProfessorHomeComponent } from './_components/professor/professor-home/professor-home.component';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { CreateCourseComponent } from './_components/professor/course/create-course/create-course.component';
import { ListCoursesComponent } from './_components/professor/course/list-courses/list-courses.component';
import { UpdateCourseComponent } from './_components/professor/course/update-course/update-course.component';
import { CreateClassComponent } from './_components/professor/class/create-class/create-class.component';
import { UpdateClassComponent } from './_components/professor/class/update-class/update-class.component';
import { ListClassesComponent } from './_components/professor/class/list-classes/list-classes.component';
import { StudentClassesListComponent } from './_components/student/student-classes-list/student-classes-list.component';
import { StudentClassComponent } from './_components/student/student-class/student-class.component';
import { StudentCoursesListComponent } from './_components/student/student-courses-list/student-courses-list.component';
import {UploadFileComponent} from './_components/uploads/singleUploadFile/upload-file.component';
import { MultiuploadfileComponent } from './_components/uploads/multiuploadfile/multiuploadfile.component';
import { UserRecoverPassComponent } from './_components/user-recover-pass/user-recover-pass.component';
import { ChangepasswordComponent } from './_components/changepassword/changepassword.component';
import { TokenInterceptor } from './_services/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ForgotPasswordComponent} from './_components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './_components/reset-password/reset-password.component';
import {RemindRegistrationCodeComponent} from './_components/remind-registration-code/remind-registration-code.component';
import { AuthInterceptor } from './_services/auth.interceptor';
import { ContactComponent } from './_components/contact/contact.component';
import { CourseComponent } from './_components/course/course.component';
import { CancelPurchaseComponent } from './_components/cancel-purchase/cancel-purchase.component';
import { CompletePurchaseComponent } from './_components/complete-purchase/complete-purchase.component';
import { LicensingComponent } from './licensing/licensing.component';
import { ConfirmuserComponent } from './_components/confirmuser/confirmuser.component';


const routes: Routes = [

  {path: 'home', component: HomeComponent},
  {path: '', component: HomeComponent},
  {path: 'userProfile', component: UserProfileComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'recoverPassword', component: UserRecoverPassComponent},
  {path: 'offers', component: OffersComponent},
  {path: 'course/:id', component: CourseComponent},
  {path: 'studentHome', component: StudentHomeComponent, canActivate: [AuthGuard]},
  {path: 'professorHome', component: ProfessorHomeComponent, canActivate: [AuthGuard]},
  {path: 'createCourse', component: CreateCourseComponent, canActivate: [AuthGuard]},
  {path: 'listCourses', component: ListCoursesComponent, canActivate: [AuthGuard]},
  {path: 'updateCourse', component: UpdateCourseComponent, canActivate: [AuthGuard]},
  {path: 'createClass', component: CreateClassComponent, canActivate: [AuthGuard]},
  {path: 'listClasses', component: ListClassesComponent, canActivate: [AuthGuard]},
  {path: 'updateClass', component: UpdateClassComponent, canActivate: [AuthGuard]},
  {path: 'studentClass', component: StudentClassComponent, canActivate: [AuthGuard]},
  {path: 'studentCoursesList', component: StudentCoursesListComponent, canActivate: [AuthGuard]},
  {path: 'studentClassesList', component: StudentClassesListComponent, canActivate: [AuthGuard]},
  {path: 'uploadFile', component: UploadFileComponent, canActivate: [AuthGuard]},
  {path: 'uploadMultiFile', component: MultiuploadfileComponent, canActivate: [AuthGuard]},
  {path: 'changepassword', component: ChangepasswordComponent},
  {path: 'forgotpassword', component: ForgotPasswordComponent},
  {path: 'resetpassword', component: ResetPasswordComponent},
  {path: 'remindregistrationcode', component: RemindRegistrationCodeComponent},
  {path: 'confirmregistration/:id', component: ConfirmuserComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'cancelPurchase/:id', component: CancelPurchaseComponent},
  {path: 'completePurchase/:id', component: CompletePurchaseComponent},
  {path: 'licensing', component: LicensingComponent},


  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AppRoutingModule { }




