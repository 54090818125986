import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UsersService} from "../../../_services/users.service";
import {DataApiService} from "../../../_services/data-api.service";
import {AlertService} from "../../../_services/alert.service";
import {AuthenticationService} from "../../../_services/authentication-service.service";
import {CourseInterface} from "../../../_models/CourseInterface";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-student-courses-list',
  templateUrl: './student-courses-list.component.html',
  styleUrls: ['./student-courses-list.component.css']
})
export class StudentCoursesListComponent implements OnInit {

  constructor( private router: Router,
               private userService: UsersService,
               private dataApiService: DataApiService,
               private alertService: AlertService,
               private authenticationService: AuthenticationService) { }

  //private courses: CourseInterface;
  private courses: CourseInterface[];
  private loading = true;
  private error = false;

  ngOnInit() {
    this.dataApiService
      .getMyCourses()
      .subscribe((data: CourseInterface[]) => {
        this.loading = false;
        this.courses = data;
        console.log(this.courses);
      }, error => {
        this.loading = false;
        this.error = true;
      });
  }

  viewcourse(course: CourseInterface ) {
    this.dataApiService.viewcourseRouter(course,this.router);
  }

  getParsedImage(image: String) : String{
    if (image.includes("/assets/images/")) {
      return image;
    }
    return environment.imagesContainerUrl + image;
  }

}
