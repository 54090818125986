import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserInterface} from "../../../../_models/UserInterface";
import {Router} from "@angular/router";
import {UsersService} from "../../../../_services/users.service";
import {DataApiService} from "../../../../_services/data-api.service";
import {AlertService} from "../../../../_services/alert.service";
import {AuthenticationService} from '../../../../_services/authentication-service.service';
import {first} from "rxjs/operators";
import { CourseInterface } from '../../../../_models/CourseInterface';

@Component({
  selector: 'app-update-course',
  templateUrl: './update-course.component.html',
  styleUrls: ['./update-course.component.css']
})
export class UpdateCourseComponent implements OnInit {
  updateCourseForm;
  loading = false;
  submitted = false;
  user: UserInterface;
  courseEdit : CourseInterface;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService

  ) { }
  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.courseEdit = this.dataApiService.selectedcourse;

    this.updateCourseForm = this.formBuilder.group({
      courseId: this.courseEdit.courseId,
      courseName: [this.courseEdit.courseName, Validators.required],
      courseDescription: [ this.courseEdit.courseDescription, Validators.required],
      language: [ this.courseEdit.courseDescription, Validators.required],
      price: [ this.courseEdit.price, Validators.required],
      imageFile: this.courseEdit.imageFile
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.updateCourseForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateCourseForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.updateCourseForm.value);
    this.dataApiService.updatecourse(this.updateCourseForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Updated successful', true);
          this.userService.redirectUserHome(this.router);
        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });
  }
}
