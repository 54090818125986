import { Injectable } from '@angular/core';
import { UserInterface } from '../_models/UserInterface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication-service.service";
import { Router } from "@angular/router";
import { ChangePasswordInterface } from '../_models/ChangePasswordInterface';
import { ObserveOnOperator } from 'rxjs/internal/operators/observeOn';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  getAll() {
    return this.http.get<UserInterface[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get(`${environment.apiUrl}/users/` + id);
  }

  register(user: UserInterface) {
    return this.http.post<any>(`${environment.apiUrl}/register`, user);
  }

  sendMessage(name: string, contactNumnber: string, email: string, subject: string, message: string) {
    const body = {name: name, contactNumber: contactNumnber, email: email, subject: subject, message: message};
    return this.http.post<any>(`${environment.apiUrl}/contact`, body);
  }

  update(user: UserInterface) {
    return this.http.post<any>(`${environment.apiUrl}/me`, {newName: user.firstName, newLastName: user.lastName});
  }

  recoverPassword(user: UserInterface) {
    return this.http.post<any>(`${environment.apiUrl}/recoverPassword`, user)
      .pipe(map(data => {
        return data;
      }));
  }

  resetPass(newPass: String, hash: String ){

     var resetCode = {
       "newPassword" : newPass,
       "confirmationHash" : hash

     }
    return this.http.post<any>(`${environment.apiUrl}/resetpassword`, resetCode)
      .pipe(map(data => {
        return data;
      }));
  }

  forgotPassword(userMAil: String) {

    var userEMAIL =
      {
        "email": userMAil
      };

    console.log(userEMAIL);
    return this.http.post<any>(`${environment.apiUrl}/forgotpassword`, userEMAIL)
      .pipe(map(data => {
        return data;
      }));
  }

  remindRegistrationCode(userMAil: String) {

    var userEMAIL =
      {
        "email": userMAil
      };

    console.log(userEMAIL);
    return this.http.post<any>(`${environment.apiUrl}/remind_registration_code`, userEMAIL)
      .pipe(map(data => {
        return data;
      }));
  }

  confirmRegistration(token: string) {
    return this.http.post<any>(`${environment.apiUrl}/confirm_registration`, {token: token});
  }

  changePassword(changePasswordRequest: ChangePasswordInterface) {
    console.log(changePasswordRequest);
    return this.http.post<any>(`${environment.apiUrl}/password`, changePasswordRequest)
      .pipe(map(data => {
        return data;
      }));
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/` + id);
  }

  redirectUserOnLogin(router: Router) {
    // 1: Professor -- 2: Student
    let user = this.authenticationService.getCurrentUser();
    if (user != null) {
      switch (user.userType) {
        case 1:
          router.navigate(['/professorHome']);
          break;
        case 2:
          router.navigate(['/studentHome']);
          break;
      }
    }
  }

  redirectUserHome(router: Router) {
    // 1: Professor -- 2: Student
    let user = this.authenticationService.getCurrentUser();
    if (user.userType === 1) {
      router.navigate(['/professorHome']);
      // router.navigate(['/']);
    } else {
      router.navigate(['/studentHome']);
    }
    //location.reload();
  }

}
