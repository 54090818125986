import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {UsersService} from '../../_services/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  registrationSuccess = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private alertService: AlertService) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', [Validators.required, Validators.minLength(6)]],
      userType: ['2', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.registerForm.value);
    //hardcode the usertype to student for now
    this.registerForm.value.userType = 2;
    this.userService.register(this.registerForm.value)
      .subscribe(
        data => {
          this.registrationSuccess = true;
        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });
  }
}
