import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../_services/users.service";
import {AlertService} from "../../_services/alert.service";
import {Router} from "@angular/router";
import {UserInterface} from "../../_models/UserInterface";
import {AuthenticationService} from "../../_services/authentication-service.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {


  userForm: FormGroup;
  loading = false;
  submitted = false;
  user: UserInterface;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }
  ngOnInit() {
    this.user =this.authenticationService.getCurrentUser();
    this.userForm = this.formBuilder.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      userId : this.user.userId
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
  
    // stop here if form is invalid
    if (this.userForm.invalid) {
      console.log('invalid');
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.userForm.value);
    this.userService.update(this.userForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Update Data successful', true);
          this.loading = false;
          this.user.firstName = this.userForm.value.firstName;
          this.user.lastName = this.userForm.value.lastName;
          this.authenticationService.setUser(this.user);
        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });
  }

  changePassword() {
    this.router.navigate(['/changepassword']);
  }
  
}
