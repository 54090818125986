import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../_services/authentication-service.service';
import {AlertService} from '../../_services/alert.service';
import {UsersService} from '../../_services/users.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {


  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  success = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              private usersService: UsersService ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('Return Url' + this.returnUrl);

  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;

    }

    this.loading = true;
    this.usersService.forgotPassword(this.f.username.value)
      .pipe()
      .subscribe(
        data => {
              this.success = true;
              this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.alertService.error('There was an error. Please try again.');
        });
  }
}

