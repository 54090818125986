import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UsersService } from "../../../../_services/users.service";
import { AlertService } from "../../../../_services/alert.service";
import { first } from "rxjs/operators";
import { DataApiService } from "../../../../_services/data-api.service";
import { UserInterface } from "../../../../_models/UserInterface";
import { AuthenticationService } from "../../../../_services/authentication-service.service";
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.css']
})
export class CreateCourseComponent implements OnInit {
  createCourseForm: FormGroup;
  loading = false;
  submitted = false;
  user: UserInterface;
  fileToUpload = localStorage.getItem('fileToUpload') === "true";
  uploader: FileUploader = new FileUploader({
    url: `${environment.apiUploadFileUrl}`, headers: [{ name: 'Token', value: `${this.authenticationService.getToken()}` }],
    itemAlias: 'file', autoUpload: true
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService

  ) { }
  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();

    this.createCourseForm = this.formBuilder.group({
      courseName: ['', Validators.required],
      courseDescription: ['', Validators.required],
      language: ['', Validators.required],
      price: ['', Validators.required]
    });
    localStorage.setItem('fileToUpload', "true");

    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      console.log('ImageUpload:uploaded:', item);
      console.log('ImageUpload:uploaded:', status);
      console.log('ImageUpload:uploaded:  ', response);

      if (status == 401) {
        this.router.navigate(['/login']);
        return;
      }
    };

    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {

      console.log('ImageUpload:uploaded:', item);
      console.log('ImageUpload:uploaded:', status);
      console.log('ImageUpload:uploaded:  ', response);

      let obj = JSON.parse(response);

      console.log('ImageUpload:uploaded message:  ', obj.fileElementId);
      this.alertService.success('File uploaded successfully', true);
      this.dataApiService.addAFileById(obj.fileElementId);
    };
  }

  // convenience getter for easy access to form fields
  get f() { return this.createCourseForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.uploader.isUploading) {
      // code block to be executed
      alert('Uploading File..');
      this.alertService.success('Uploading File..', true);
      setTimeout(function () {
      }, 500);
    }

    if (this.createCourseForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.createCourseForm.value);
    this.dataApiService.savecourse(this.createCourseForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.userService.redirectUserHome(this.router);
          this.alertService.success('Course created successful', true);
        },
        error => {
          console.log('LLegando  datos' + error);

          for (var property in error) {
            console.log(property + '=' + error[property]);
          }

          this.alertService.error('Error ');
          this.loading = false;
        });

  }

  uploadFromForm() {
    localStorage.setItem('fileToUpload', "false");
    this.router.navigate(['/uploadFile']);

  }
}
