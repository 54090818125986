import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataApiService } from 'src/app/_services/data-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  private courseId: number;
  private loading = false;
  private error = false;
  private invalidCourse = false;
  private course;
  private classes;
  private teacherName;

  constructor(private activatedRoute: ActivatedRoute,
    private dataApiService: DataApiService) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.params['id'];
    if (this.courseId > 0) {
      this.dataApiService.getcourseById(this.courseId)
        .subscribe(data => {
          this.loading = false;
          this.course = data.course;
          this.classes = data.classes;
          this.teacherName = data.teacherFirstName + " " + data.teacherLastName;
          console.log(this.course);
          console.log(data);
        }, error => {
          this.loading = false;
          this.error = true;
        });
    } else {
      this.invalidCourse = true;
    }
  }

  getParsedImage(image: String): String {
    if (image.includes("/assets/images/")) {
      return image;
    }
    console.log(environment.imagesContainerUrl + image);
    return environment.imagesContainerUrl + image;
  }
}
