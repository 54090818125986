import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/*--Components--*/
import { NavigationComponent } from './_components/navigation/navigation.component';
import { LoginComponent } from './_components/login/login.component';
import { RegisterComponent } from './_components/register/register.component';
import { HomeComponent } from './_components/home/home.component';
import { AlertComponent } from './_components/alert/alert.component';
import { StudentHomeComponent } from './_components/student/student-home/student-home.component';
import { ProfessorHomeComponent } from './_components/professor/professor-home/professor-home.component';
import { OffersComponent } from './_components/offers/offers.component';


/*--Providers--*/
import {AlertService} from './_services/alert.service';
import {AuthenticationService} from './_services/authentication-service.service';
import {AuthGuard} from './_guards/auth.guard';
import {UsersService} from './_services/users.service';
import { UserProfileComponent } from './_components/user-profile/user-profile.component';
import { CreateCourseComponent } from './_components/professor/course/create-course/create-course.component';
import { ListClassesComponent } from './_components/professor/class/list-classes/list-classes.component';
import { StudentCoursesListComponent } from './_components/student/student-courses-list/student-courses-list.component';
import { StudentClassesListComponent } from './_components/student/student-classes-list/student-classes-list.component';
import { StudentClassComponent } from './_components/student/student-class/student-class.component';
import { ListCoursesComponent } from './_components/professor/course/list-courses/list-courses.component';
import { UpdateCourseComponent } from './_components/professor/course/update-course/update-course.component';
import { CreateClassComponent } from './_components/professor/class/create-class/create-class.component';
import { UpdateClassComponent } from './_components/professor/class/update-class/update-class.component';
import {DataApiService} from './_services/data-api.service';
import {PaymentService} from './_services/payment.service';
import { UploadFileComponent } from './_components/uploads/singleUploadFile/upload-file.component';
import {FileSelectDirective} from 'ng2-file-upload';
import { MultiuploadfileComponent } from './_components/uploads/multiuploadfile/multiuploadfile.component';
import { UserRecoverPassComponent } from './_components/user-recover-pass/user-recover-pass.component';
import { ChangepasswordComponent } from './_components/changepassword/changepassword.component';
import {FileService} from './_services/file.service';
import { ForgotPasswordComponent } from './_components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './_components/reset-password/reset-password.component';
import { RemindRegistrationCodeComponent } from './_components/remind-registration-code/remind-registration-code.component';
import { ContactComponent } from './_components/contact/contact.component';
import { CourseComponent } from './_components/course/course.component';
import { CancelPurchaseComponent } from './_components/cancel-purchase/cancel-purchase.component';
import { CompletePurchaseComponent } from './_components/complete-purchase/complete-purchase.component';
import { LicensingComponent } from './licensing/licensing.component';
import { ConfirmuserComponent } from './_components/confirmuser/confirmuser.component';
import { NavigationService } from './_services/navigation.service'



@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    AlertComponent,
    StudentHomeComponent,
    ProfessorHomeComponent,
    OffersComponent,
    UserProfileComponent,
    CreateCourseComponent,
    ListClassesComponent,
    StudentCoursesListComponent,
    StudentClassesListComponent,
    StudentClassComponent,
    ListCoursesComponent,
    UpdateCourseComponent,
    CreateClassComponent,
    UpdateClassComponent,
    UploadFileComponent,
    FileSelectDirective,
    MultiuploadfileComponent,
    UserRecoverPassComponent,
    ChangepasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RemindRegistrationCodeComponent,
    ContactComponent,
    CourseComponent,
    CancelPurchaseComponent,
    CompletePurchaseComponent,
    LicensingComponent,
    ConfirmuserComponent


  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UsersService,
    DataApiService,
    PaymentService,
    FileService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {



}
