import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map, single } from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {UserInterface} from '../_models/UserInterface';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';



@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  constructor(private http: HttpClient) {}
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  login(username: string, password: string) {
    console.log( 'enviando datos' );
    return this.http.post<any>(`${environment.apiUrl}/weblogin`, { mail: username, password: password })
     .pipe(map(data => {

        // login successful if there's a jwt token in the response
        console.log(data);
        for(var property in data) {
          console.log(property + '=' + data[property]);
        }
       let user_string = JSON.stringify(data.user);
       console.log( 'LLegando  datos User' + user_string  );

        if (data.user && data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setUser(data.user);
          this.setToken(data.token);
          console.log('localStorage.setItem ---> currentUser');
        }else{
          console.log('No localStorage.setItem ---> currentUser');
        }
        return data;
      }));
  }

/*
  login(username: string, password: string) {
    console.log( 'enviando datos' );
    return this.http.post<any>(`${environment.apiUrl}/login`, { username: username, password: password })
     .pipe(map(data => {
        // login successful if there's a jwt token in the response
        console.log(data);
        for(var property in data) {
          console.log(property + '=' + data[property]);
        }
       let user_string = JSON.stringify(data.user);
       console.log( 'LLegando  datos User' + user_string  );

        if (data.user && data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setUser(data.user);
          this.setToken(data.token);
          console.log('localStorage.setItem ---> currentUser');
        }else{
          console.log('No localStorage.setItem ---> currentUser');
        }
        return data;
      }));
  }*/

  public setUser(user: UserInterface): void {
    let user_string = JSON.stringify(user);
    console.log('set user: ' + user_string);
    localStorage.setItem('currentUser', user_string);
  }

  public setToken(token): void {
    console.log("token set again: " + token);
    localStorage.setItem('accessToken', token);
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }


  getCurrentUser(): UserInterface {
    let user_string = localStorage.getItem('currentUser');
    console.log(' El usuario actual es: ' + user_string);
    if (!isNullOrUndefined(user_string)) {
      let user: UserInterface = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  logoutUser()  {
    let accessToken = localStorage.getItem('accessToken');
    const url_api = `http://localhost:3000/api/Users/logout?access_token=${accessToken}`;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    return this.http.post<UserInterface>(url_api, { headers: this.headers });
  }

}

