import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataApiService } from 'src/app/_services/data-api.service';
import { NavigationService } from 'src/app/_services/navigation.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public app_name = environment.app_name;
  private downloading: boolean;
  constructor(private dataApiService: DataApiService, 
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.navigationService.getNavigationObservable().subscribe(
      screenName => {
        console.log(screenName);
        if (screenName == 'download') {
          this.scrollToDownload();
        }
      }
    );

    const pathFromRoot = this.activatedRoute.snapshot.pathFromRoot[0];
    if (pathFromRoot != null && pathFromRoot.fragment == 'download') {
      this.scrollToDownload();
    }

    this.registerLastVisit();
  }

  registerLastVisit() {
    const lastVisit = new Date(localStorage.getItem('lastVisit'));
    const today = new Date();
    if (lastVisit) {
      const diff = Math.abs(today.getTime() - lastVisit.getTime());
      const daysDiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (daysDiff < 1) {
        return;
      }
    }

    localStorage.setItem('lastVisit', today.getTime().toString());
    this.dataApiService.registerWebVisit();
  }

  scrollToDownload() {
    let downloadContainer = document.getElementById('downloadContainer');
    downloadContainer.scrollIntoView();
  }

  downloadZip() {
    this.downloading = true;
    this.dataApiService.downloadZipDemo()
      .subscribe(
        response => {          
          this.downLoadFile(response, null, "demo.zip");
        },
        error => this.processDownloadError(error));
  }

  downloadTar() {
    this.downloading = true;
    this.dataApiService.downloadTarDemo()
      .subscribe(
        response => {          
          this.downLoadFile(response, null, "demo.tar");
        },
        error => this.processDownloadError(error));
  }

  downloadExe() {
    this.downloading = true;
    this.dataApiService.downloadExeDemo()
      .subscribe(
        response => {          
          this.downLoadFile(response, null, "sakana.exe");
        },
        error => this.processDownloadError(error));
  }

  processDownloadError(error: any) {
    this.downloading = false;
    console.log('Error downloading file');
  }

  downLoadFile(data: any, type: string, fileName: string) {
    this.downloading = false;

    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);

    saveAs(blob, fileName);
  }
}
