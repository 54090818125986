import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from "../../../_services/users.service";
import { DataApiService } from "../../../_services/data-api.service";
import { AlertService } from "../../../_services/alert.service";
import { AuthenticationService } from "../../../_services/authentication-service.service";
import { ClassInterface } from "../../../_models/Class-Interface";
import { FileElement } from "../../../_models/file-element";
import { CourseInterface } from "../../../_models/CourseInterface";
import { Location } from '@angular/common';
import { StudentCourseClassInterface } from 'src/app/_models/CourseClassInterface';

@Component({
  selector: 'app-student-classes-list',
  templateUrl: './student-classes-list.component.html',
  styleUrls: ['./student-classes-list.component.css']
})
export class StudentClassesListComponent implements OnInit {


  constructor(private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private location: Location) { }

  //private classes: ClassInterface[];
  private classes: StudentCourseClassInterface[];
  private course: CourseInterface;

  ngOnInit() {
    this.course = this.dataApiService.getSelectedCourse();
    if (this.course == null) {
      console.log("Selected course null...");
      this.location.back();
    } else {
      console.log('retrieving classes for course');
      this.dataApiService
        .getClassesFromCourse()
        .subscribe((data: StudentCourseClassInterface[]) => {
          console.log('got classes from server');
          this.classes = data;
          console.log(this.classes);
          console.log(this.classes[0].courseClass.className);
        });
    }
  }


  openClass(courseClass: StudentCourseClassInterface) {
    this.dataApiService.viewclassRouter(courseClass, this.router);
  }

}
