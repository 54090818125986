import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../../../_services/users.service";
import {DataApiService} from "../../../../_services/data-api.service";
import {AlertService} from "../../../../_services/alert.service";
import {Router} from "@angular/router";
import {UserInterface} from "../../../../_models/UserInterface";
import {first} from "rxjs/operators";
import {AuthenticationService} from "../../../../_services/authentication-service.service";
import { ClassInterface } from '../../../../_models/Class-Interface';

@Component({
  selector: 'app-update-class',
  templateUrl: './update-class.component.html',
  styleUrls: ['./update-class.component.css']
})
export class UpdateClassComponent implements OnInit {

  updateClassForm: FormGroup;
  loading = false;
  submitted = false;
  user: UserInterface;
  classEdit: ClassInterface;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) { }
  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.classEdit = this.dataApiService.selectedClass;

    this.updateClassForm = this.formBuilder.group({
      classId: this.classEdit.classId,
      className: [this.classEdit.className, Validators.required],
      classDescription: [this.classEdit.classDescription, Validators.required],
      courseId : this.classEdit.courseId,
      files:  this.classEdit.fileElementList


    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.updateClassForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateClassForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('Los Valores son: ')
    console.log(this.updateClassForm.value);
    this.dataApiService.updateClass(this.updateClassForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Class Update successful', true);
          // this.router.navigate(['/login']);
          this.userService.redirectUserHome(this.router);
        },
        error => {
          this.alertService.error('Error ');
          this.loading = false;
        });

  }
}
