import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication-service.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/_services/navigation.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  navbarOpen = false;

  constructor(private authService: AuthenticationService, 
    private activatedRoute: ActivatedRoute, 
    private navigationService: NavigationService,
    private location: Location,
    private router: Router) { }
  public app_name = environment.app_name;
  public isLogged = false;
  public isProfessor = false;
  public isConfirmed = true;



  ngOnInit() {
    this.onCheckUser();
    this.activatedRoute.url
      .subscribe(url => console.log('The URL changed to: ' + url));
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  onLogout(): void {
    this.authService.logoutUser();
    location.reload();
  }

  onCheckUser(): void {
    const user = this.authService.getCurrentUser();

    if (user === null) {
      this.isLogged = false;
      this.isProfessor = false;
      this.isConfirmed = true;
    } else {
      this.isLogged = true;
      // 1: Professor -- 2: Student
      if (user.userType === 1) {
        this.isProfessor = true;
      } else {
        this.isProfessor = false;
      }
      if (!user.confirmed) {
        this.isConfirmed = false;
      }
    }

  }

  downloadDemo(): void {
    const path = this.location.path();
    if (path.length == 0) {
      this.navigationService.sendNavigationEvent('download');
    } else {
      console.log('In another screen');
      this.router.navigateByUrl('/#download');
    }
  }

}
