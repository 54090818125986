import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../../../../_services/users.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { AlertService } from '../../../../_services/alert.service';
import { AuthenticationService } from '../../../../_services/authentication-service.service';
import { CourseInterface } from '../../../../_models/CourseInterface';
import { ClassInterface } from '../../../../_models/Class-Interface';
import { FileElement } from '../../../../_models/file-element';
import {first} from "rxjs/operators";

@Component({
  selector: 'app-list-classes',
  templateUrl: './list-classes.component.html',
  styleUrls: ['./list-classes.component.css']
})
export class ListClassesComponent implements OnInit {


  private classes: ClassInterface;
  private  files: FileElement[];

  constructor( private router: Router,
               private userService: UsersService,
               private dataApiService: DataApiService,
               private alertService: AlertService,
               private authenticationService: AuthenticationService) { }




  ngOnInit() {

    this.dataApiService
      .getMyClasses()
      .subscribe((data: ClassInterface) => (this.classes = data));


    this.files =  this.classes.fileElementList;

  }





  deleteClass(class_: ClassInterface) {

    this.dataApiService.deleteClass( class_.classId ).pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Class delete successful', true);
          // this.router.navigate(['/login']);
          location.reload();
          //  this.userService.redirectUserHome(this.router);
        },

        error => {
          this.alertService.error('Error ');
        });
    //   this.alertService.success('Update course');
  }


  updateClass(class_: ClassInterface) {
    this.dataApiService.updateclassRouter(class_ , this.router);
    //   this.alertService.success('Update course');
  }

}
