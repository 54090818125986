import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  private url = `${environment.apiUrl}`;
  constructor(private http: HttpClient) { }


  makePayment(courseId: number, router: Router) {
    console.log('purchase/'+courseId);
    return this.http.get<any>(`${environment.apiUrl}/purchase/` + courseId);
  }

  completePurchase(courseId: number, paymentToken: string, paymentId: string, payerId: string) {
    console.log('complete purchase');
    console.log(`${environment.apiUrl}/completePurchase/` + courseId + "/" + paymentToken + "/" + paymentId + "/" + payerId);
    return this.http.post<any>(`${environment.apiUrl}/completePurchase/` + courseId + "/" + paymentToken + "/" + paymentId + "/" + payerId, null);
  }
}
