import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../_services/authentication-service.service';
import {AlertService} from '../../_services/alert.service';
import {UsersService} from '../../_services/users.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  resetPasswordForm: FormGroup;
  loading = false;
  success = false;
  submitted = false;
  returnUrl: string;
  resetToken: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private usersService: UsersService ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.resetToken = this.route.snapshot.queryParams['token'];
    console.log(this.resetToken);

    this.resetPasswordForm = this.formBuilder.group({
      code: [this.resetToken, Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.usersService.resetPass(this.f.password.value, this.f.code.value)
      .pipe()
      .subscribe(
        data => {
          this.success = true;
        },
        error => {
          this.alertService.error('There was an error, please try again later.');
          this.loading = false;
        });
  }
}

