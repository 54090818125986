import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { saveAs } from 'file-saver';





@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor( private http: HttpClient) {}


  downloadFile( fileName: string) {
  const url = `${environment.apiUrl}/web-uploads/${fileName}`;
  //const options = new RequestOptions({responseType: ResponseContentType.Blob });

  // Process the file downloaded
  this.http.get(url, { responseType: 'blob' }).subscribe(res => {
     //fileName = getFileNameFromResponseContentDisposition(res);
    saveAs(res, fileName);
  });
}

  dataURLtoFile(dataurl, filename, format) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    saveAs(new File([u8arr], filename, {type: format}));
  }

}


