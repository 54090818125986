import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from "../../../_services/users.service";
import { DataApiService } from "../../../_services/data-api.service";
import { AlertService } from "../../../_services/alert.service";
import { AuthenticationService } from "../../../_services/authentication-service.service";
import { ClassInterface } from "../../../_models/Class-Interface";
import { FileElement } from "../../../_models/file-element";
import { CourseInterface } from "../../../_models/CourseInterface";
import { FileService } from '../../../_services/file.service';
import { environment } from '../../../../environments/environment';
import { StudentCourseClassInterface } from 'src/app/_models/CourseClassInterface';
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-class',
  templateUrl: './student-class.component.html',
  styleUrls: ['./student-class.component.css']
})
export class StudentClassComponent implements OnInit {


  constructor(private router: Router,
    private userService: UsersService,
    private dataApiService: DataApiService,
    private fileService: FileService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private location: Location) { }

  private studentClass: StudentCourseClassInterface;
  private apiUrl: String;

  ngOnInit() {
    this.studentClass = this.dataApiService.getSelectedStudentCourseClass();
    if (this.studentClass == null) {
      console.log('student class selected is null')
      this.location.back();
    }
  }

  openFile(file: FileElement) {
    console.log(file);
    this.dataApiService.getClassFile(file.fileElementId)
    .subscribe(response => {
      console.log('got file from server');
      this.downLoadFile(response, null);
    });
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }
}

