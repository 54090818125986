import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-confirmuser',
  templateUrl: './confirmuser.component.html',
  styleUrls: ['./confirmuser.component.css']
})
export class ConfirmuserComponent implements OnInit {

  loading = true;
  error = false;
  success = false;
  private token: string;
  constructor(private activatedRoute: ActivatedRoute,
    private usersService: UsersService) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params['id'];
    console.log(this.token);

    this.usersService.confirmRegistration(this.token)
      .subscribe(data => {
        this.loading = false;
        this.success = true;
      }, error => {
        this.loading = false;
        console.log(error);
        this.error = true;
      })
  }

}
