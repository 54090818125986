import { Component, OnInit } from '@angular/core';
import { CourseInterface } from "../../_models/CourseInterface";
import { DataApiService } from "../../_services/data-api.service";
import { PaymentService } from "../../_services/payment.service";
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserInterface } from '../../_models/UserInterface';
import { AuthenticationService } from '../../_services/authentication-service.service';
import { isUndefined } from "util";
import { environment } from '../../../environments/environment';
import { AlertService } from 'src/app/_services/alert.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  user: UserInterface;
  private hasErrors = false;
  private purchaseLoading = false;
  private loading = true;

  constructor(private dataApi: DataApiService,
    private paymentService: PaymentService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }
  private courses: CourseInterface;


  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.dataApi
      .getOffers()
      .subscribe((data: CourseInterface) => {
        this.loading = false;
        this.courses = data;
        console.log(this.courses);
      }, error => {
        this.hasErrors = true;
        this.loading = false;
      });
  }

  myFunc(courseId) {
    console.log("function user" + this.user);
    if (isUndefined(this.user) || this.user === null) {
      this.router.navigate(['/register']);
    } else {
      this.purchaseLoading = true;
      this.paymentService.makePayment(courseId, this.router)
      .subscribe(data => {
        console.log(data);

        if (data == null) {
          this.alertService.error('There was an error, please try again later.');
          this.purchaseLoading = false;
        }

        if (data.resultOk == true) {
          console.log('ok!!!');
          this.router.navigate(["studentCoursesList"]);
        } else {
          console.log(data['redirect_url']);
          if (data['redirect_url'] != undefined) {
            window.location.href = data['redirect_url'];
          } else {
            this.alertService.error('There was an error, please try again later');
          }
        }
      }, error => {
        this.hasErrors = true;
        this.purchaseLoading = false;
      });
    }
  }

  getParsedImage(image: String): String {
    if (image.includes("/assets/images/")) {
      return image;
    }
    return environment.imagesContainerUrl + image;
  }
}
