import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../_services/users.service";
import {AlertService} from "../../_services/alert.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../_services/authentication-service.service";
import { ChangePasswordInterface } from 'src/app/_models/ChangePasswordInterface';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  changePasswordBody: ChangePasswordInterface;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.changePasswordBody = new ChangePasswordInterface();
    this.passwordForm = this.formBuilder.group({
      newPassword: [this.changePasswordBody.newPassword, [Validators.required, Validators.minLength(6)]],
      oldPassword: [this.changePasswordBody.newPassword, [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.changePassword(this.passwordForm.value)
    .subscribe((response)=>{
      this.userService.redirectUserHome(this.router);
    },
    (error) => {
      this.loading = false;
      const errorDescription: string = error.error.errorDescription;
      console.log(errorDescription);
      if (errorDescription == undefined) {
        this.alertService.error('Error.');
      } else {
        if (errorDescription.length > 0) {
          this.alertService.error('Invalid password.');  
        } else {
          this.alertService.error('Error.');
        }
      }
    });
    
  }
}
