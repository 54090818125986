import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../../../../_services/users.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { AlertService } from '../../../../_services/alert.service';
import { AuthenticationService } from '../../../../_services/authentication-service.service';
import { CourseInterface } from '../../../../_models/CourseInterface';
import { first } from 'rxjs/operators';



@Component({
  selector: 'app-list-courses',
  templateUrl: './list-courses.component.html',
  styleUrls: ['./list-courses.component.css']
})
export class ListCoursesComponent implements OnInit {

  constructor( private router: Router,
               private userService: UsersService,
               private dataApiService: DataApiService,
               private alertService: AlertService,
               private authenticationService: AuthenticationService) { }

  private courses: CourseInterface;

  ngOnInit() {
      this.dataApiService
      .getMyProfessorCourses()
        .subscribe((data: CourseInterface) => (this.courses = data));
  }

  updatecourse(course: CourseInterface ) {
    this.dataApiService.updatecourseRouter(course, this.router);
  }

  deletecourse(course: CourseInterface ) {
    console.log("el Curso es: " + course.courseName);
    this.dataApiService.deletecourse( course.courseId ).pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Course delete successful', true);
          location.reload();
        },
        error => {
          this.alertService.error('Error ');
        });
  }

}
