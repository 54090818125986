import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/_services/payment.service';

@Component({
  selector: 'app-complete-purchase',
  templateUrl: './complete-purchase.component.html',
  styleUrls: ['./complete-purchase.component.css']
})
export class CompletePurchaseComponent implements OnInit {

  private loading = true;
  private error = false;
  private courseId: number;
  private course;
  constructor(private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.params['id'];
    const paymentId = this.activatedRoute.snapshot.queryParams['paymentId'];
    const payerId = this.activatedRoute.snapshot.queryParams['PayerID'];
    const paymentToken = this.activatedRoute.snapshot.queryParams['token'];    

    this.paymentService.completePurchase(this.courseId, paymentToken, paymentId, payerId)
    .subscribe(data=>{
      this.loading = false;
      if (data != null) {
        this.course = data.course;
      } else {
        this.error = true;
      }
    }, error => {
      this.loading = false;
      this.error = true;
    });

  }

}
