import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from 'src/app/_services/users.service';
import { map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  loading = false;
  submitted = false;
  messageSent = false;
  errorMessage = false;

  constructor(private formBuilder: FormBuilder, private userService: UsersService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      contactnumber: ['',Validators.nullValidator],
      username: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  get f() {return this.contactForm.controls;}

  onSubmit() {
    this.errorMessage = false;
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.sendMessage(this.f.name.value,
      this.f.contactnumber.value,
      this.f.username.value,
      this.f.subject.value,
      this.f.message.value)
      .subscribe(value => {this.messageSent = true},
        error => {this.errorMessage = true; this.loading = false;})
  }

}
