import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cancel-purchase',
  templateUrl: './cancel-purchase.component.html',
  styleUrls: ['./cancel-purchase.component.css']
})
export class CancelPurchaseComponent implements OnInit {

  private courseId: number;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.params['id'];
    //retrieve course info in the future...
    console.log(this.courseId);
    console.log(this.activatedRoute.snapshot.queryParams['token']);
  }

}
