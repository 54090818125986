import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CourseInterface } from "../_models/CourseInterface";
import { AuthenticationService } from "./authentication-service.service";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserInterface } from '../_models/UserInterface';
import { Router } from '@angular/router';
import { ClassInterface } from '../_models/Class-Interface';
import { CredentialsInterface } from '../_models/credentials-interface';
import { FileElement } from '../_models/file-element';
import { isUndefined } from 'util';
import { StudentCourseClassInterface } from '../_models/CourseClassInterface';


@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  constructor(private http: HttpClient, private authService: AuthenticationService) { }
  courses: Observable<any>;
  course: Observable<any>;
  selectedcourse: CourseInterface;
  selectedClass: ClassInterface;
  selectedStudentClass: StudentCourseClassInterface;
  files: FileElement[] = [];


  /* = {


  courseId: null,
  courseName: '',
  courseDescription: '',
  language: '',
  price: null,
  courseProfessorId: null // ,
  //  imageCourse: ''
  };*/



  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: this.authService.getToken(),
    'Token': this.authService.getToken(),
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'

  });

  downloadZipDemo() {
    const url_api = `${environment.apiUrl}/download/zip`;
    return this.http.get(url_api, { responseType: 'arraybuffer' });
  }

  downloadTarDemo() {
    const url_api = `${environment.apiUrl}/download/tar`;
    return this.http.get(url_api, { responseType: 'arraybuffer' });
  }

  downloadExeDemo() {
    const url_api = `${environment.apiUrl}/download/exe`;
    return this.http.get(url_api, { responseType: 'arraybuffer' });
  }

  getClassFile(fileId: number) {
    const url_api = `${environment.apiUrl}/file/${fileId}`;
    return this.http.get(url_api, { responseType: 'arraybuffer', headers: this.headers });
  }

  getAllcourses() {
    const url_api = `${environment.apiUrl}/courses`;
    return this.http.get(url_api);
  }
  getNotOffers() {
    const url_api = `${environment.apiUrl}/courses?filter[where][oferta]=0`;
    return this.http.get(url_api);
  }

  getcourseById(id: number) {
    const url_api = `${environment.apiUrl}/course/${id}`;
    return this.http.get<any>(url_api, { headers: this.headers })
      .pipe(map(data => {
        return data;
      }));
  }


  getAll() {
    return this.http.get<UserInterface[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get(`${environment.apiUrl}/users/` + id);
  }

  getOffers() {
    const url_api = `${environment.apiUrl}/coursesOffers`;
    this.courses = this.http.get(url_api);
    console.log(this.courses.subscribe(data => {
      console.log(data)
    }));
    return (this.courses);
  }

  getMyProfessorCourses() {
    const url_api = `${environment.apiUrl}/professorcourses`;
    return this.http.get<any>(url_api, { headers: this.headers })
      .pipe(map(data => {
        return data;
      }));
  }

  getMyCourses() {
    console.log('retrieving my courses...');
    const url_api = `${environment.apiUrl}/mycourses`;
    return this.http.get<any>(url_api, { headers: this.headers })
      .pipe(map(data => {
        return data;
      }));
  }

  getMyClasses() {
    const url_api = `${environment.apiUrl}/myclasses`;
    return this.http.get<any>(url_api, { headers: this.headers })
      .pipe(map(data => {
        console.log('LLegando  datos' + data);
        for (var property in data) {
          console.log(property + '=' + data[property]);
        }
        return data;
      }));
  }

  getSelectedCourse() {
    return this.selectedcourse;
  }

  getClassesFromCourse() {
    const url_api = `${environment.apiUrl}/classes/` + this.selectedcourse.courseId.toString();
    return this.http.get<any>(url_api, { headers: this.headers })
      .pipe(map(data => {
        console.log('LLegando  datos' + data);
        for (var property in data) {
          console.log(property + '=' + data[property]);
        }
        return data;
      }));
  }

  getMyClass() {
    return this.selectedClass;
  }

  getSelectedStudentCourseClass() {
    return this.selectedStudentClass;
  }


  savecourse(course: CourseInterface) {
    const url_api = `${environment.apiUrl}/course`;
    if (typeof this.files !== 'undefined' && this.files.length > 0) {
      course.imageFile = this.files[0];
    }
    return this.http.put<any>(url_api, course)
      .pipe(map(data => {
        return data;
      }));
  }

  updatecourse(course: CourseInterface) {
    const url_api = `${environment.apiUrl}/course`;
    return this.http.post<any>(url_api, course)
      .pipe(map(data => {
        return data;
      }));
  }

  updatecourseRouter(course: CourseInterface, router: Router) {
    this.selectedcourse = course;
    router.navigate(['/updateCourse']);
  }

  deletecourse(id: number) {
    console.log("course id" + id);
    const url_api = `${environment.apiUrl}/course/` + id.toString();
    return this.http.delete<any>(url_api)
      .pipe(map(data => {
        console.log("data:" + data);
        return data;
      }));
  }

  deleteClass(id: number) {
    console.log("class id" + id);
    const url_api = `${environment.apiUrl}/class/` + id.toString();
    return this.http.delete<any>(url_api)
      .pipe(map(data => {
        console.log("data:" + data);
        return data;
      }));
  }

  updateclassRouter(class_: ClassInterface, router: Router) {
    this.selectedClass = class_;
    router.navigate(['/updateClass']);
  }

  saveclass(class_: ClassInterface) {
    console.log("Datos a enviar" + class_);
    if (typeof this.files !== 'undefined' && this.files.length > 0) {
      class_.fileElementList = this.files;
    }
    console.log(" class_:" + class_);
    const url_api = `${environment.apiUrl}/class`;
    return this.http.put<any>(url_api, class_, { headers: this.headers })
      .pipe(map(data => {
        console.log("data:" + JSON.stringify(data));
        return data;
      }));
  }

  updateClass(class_: ClassInterface) {
    console.log("Datos a enviar" + class_);
    const url_api = `${environment.apiUrl}/class`;
    return this.http.post<any>(url_api, class_, { headers: this.headers })
      .pipe(map(data => {
        console.log("Class data:" + data);
        return data;
      }));
  }

  viewcourseRouter(course: CourseInterface, router: Router) {
    this.selectedcourse = course;
    router.navigate(['/studentClassesList']);
  }

  viewclassRouter(courseClass: StudentCourseClassInterface, router: Router) {
    this.selectedStudentClass = courseClass;
    router.navigate(['/studentClass']);
  }

  addAFile(file_: FileElement) {
    this.files.push(file_);
  }


  addAFileById(id: number) {

    console.log('Llego: ' + id);
    const file_ = new FileElement();
    console.log('file' + file_);
    let y = +id;
    console.log('Valor de y ' + y);
    if (!isUndefined(y) || y != null) {
      file_.fileElementId = y;
      this.files.push(file_);
      console.log('Pushed file');
    } else {

      console.log('No llego el id del file Item');
    }

    for (var fileele of this.files) {
      console.log("File id" + fileele.fileElementId);
    }


  }

  getAllFilesInMemory() {
    return this.files;
  }

  registerWebVisit() {
    this.http.post<any>(`${environment.apiUrl}/web/visit`, null)
      .subscribe(
        (data) => console.log('Tracked'),
        (error) => console.log(error)
      );
  }
}
